<template>
  <div>
    <ts-page-title
      :title="$t('payUserVariable.pageTitle')"
      :breadcrumb="[
        { text: $t('home'), href: '/' },
        {
          text: $t('payUserVariable.pageTitle'),
          active: true,
        },
      ]"
    />
    <div>
      <ts-panel>
        <ts-panel-wrapper class="tw-flex tw-items-center tw-justify-between">
          <Button type="info" ghost @click="addNew">{{
            $t("addNew")
          }}</Button>
          <div class="tw-justify-end tw-space-x-2">
            <Input
              search
              v-model="search"
              :placeholder="$t('payUserVariable.search')"
              :style="width"
              @on-focus="() => (width = 'width: 500px')"
              @on-blur="() => (width = 'width: 300px')"
            />
          </div>
        </ts-panel-wrapper>
        <Table
          :columns="columns"
          :data="resources"
          size="small"
          :loading="loading"
          max-height="700"
          stripe
        >
          <template slot-scope="{ row }" slot="action">
            <a
              href="#"
              class="text-primary ml-2"
              @click.prevent="onEdit(row)"
              v-tooltip="$t('edit')"
            >
              <Icon type="ios-create" size="20" />
            </a>
            <Poptip
              confirm
              :title="$t('areYouSure')"
              @on-ok="onDelete(row)"
              :transfer="true"
              :ok-text="$t('yes')"
              :cancel-text="$t('no')"
            >
              <a
                class="ml-2 text-danger"
                v-tooltip="$t('delete')"
                :disabled="row._deleting"
              >
                <i class="fas fa-circle-notch fa-spin" v-if="row._deleting"></i>
                <Icon type="ios-trash" size="20" v-else />
              </a>
            </Poptip>
          </template>
        </Table>
        <div class="tw-flex tw-items-center tw-justify-end tw-p-4">
          <ts-i-pagination
            v-model="pagination"
            @navigate="fetchData"
          ></ts-i-pagination>
        </div>
        <Modal
          v-model="showForm"
          draggable
          sticky
          scrollable
          :mask="false"
          :footer-hide="true"
          :z-index="1000"
          :closable="true"
          :title="$t('payUserVariable.pageTitle')"
          width="80%"
        >
          <from-action
            ref="form_action"
            @cancel="clearEdit"
            @fetchData="fetchData"
          />
        </Modal>
      </ts-panel>
    </div>
  </div>
</template>

<script>
import { debounce } from "lodash";
import { mapState } from "vuex";
import FromAction from "./form.vue";

export default {
  name: "pay-user-variable",
  components: {
    FromAction,
  },
  data() {
    return {
      loading: false,
      showForm: false,
      width: "width: 300px",
    };
  },
  computed: {
    ...mapState("payroll/payUserVariable", ["resources", "pagination"]),
    search: {
      get() {
        return this.$store.state.payroll.payUserVariable.search;
      },
      set(newValue) {
        this.$store.commit("payroll/payUserVariable/SET_SEARCH", newValue);
        this.$store.commit("payroll/payUserVariable/RESET_CURRENT_PAGE");
      },
    },
    columns() {
      return [
        {
          title: this.$t("payUserVariable.uvName"),
          key: "uv_name",
        },
        {
          title: this.$t("payUserVariable.description"),
          key: "description",
        },
        {
          title: this.$t("payUserVariable.formula"),
          key: "formula",
        },
        {
          title: this.$t("payUserVariable.dataType"),
          key: "data_type",
        },
        {
          title: this.$t("actions"),
          key: "action",
          slot: "action",
          align: "center",
          className: "tw-space-x-2",
          width: 90,
        },
      ];
    },
  },
  methods: {
    fetchData(attributes) {
      this.loading = true;
      this.$store
        .dispatch("payroll/payUserVariable/fetch", attributes)
        .catch((error) => {
          this.loading = false;
          this.notice({ type: "error", text: error.message });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async onEdit(record) {
      this.showForm = true;
      await this.$store.commit("payroll/payUserVariable/SET_EDIT_DATA", record);
      await this.$refs.form_action.setEditData();
    },
    async onDelete(record) {
      record._deleting = true;
      this.$store
        .dispatch("payroll/payUserVariable/destroy", record.id)
        .then((response) => {
          this.fetchData();
          record._deleting = false;
          this.notice({
            type: response.message_id == 0 ? "success" : "warning",
            text: response.message,
          });
        })
        .catch((error) => {
          this.notice({ type: "error", text: error.message });
          record._deleting = false;
        });
    },
    clearEdit() {
      this.showForm = false;
      this.$refs.form_action.setEditData();
      this.$refs.form_action.clearInput();
      this.$store.commit("payroll/payUserVariable/SET_EDIT_DATA", {});
    },
    async addNew() {
      this.showForm = true;
      await this.$store.commit("payroll/payUserVariable/SET_EDIT_DATA", {});
      await this.$refs.form_action.setEditData();
      this.$refs.form_action.clearInput();
    },
    notice(not) {
      this.$Notice[not.type]({
        title: "PAYROLL FORMULA",
        desc: not.text,
      });
    },
  },
  watch: {
    search: debounce(function (search) {
      this.fetchData({ search });
    }, 500),
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.fetchData();
    });
  },
};
</script>
