<template>
  <div class="tw-text-xs">
    <form>
      <div class="tw-flex tw--mx-2">
        <div class="tw-w-1/3 tw-px-2">
          <div class="form-group m-b-10">
            <label class="col-form-label control-label required">{{
              $t("payUserVariable.uvName")
            }}</label>
            <div>
              <ts-loading-banner :loading="gettingVariableName" fontSize="1rem">
                <Input
                  v-model="model.uv_name"
                  disabled
                  :class="{ 'is-invalid': errors.has('uv_name') }"
                />
              </ts-loading-banner>
              <div class="invalid-feedback" v-if="errors.has('uv_name')">
                {{ errors.first("uv_name") }}
              </div>
            </div>
          </div>
        </div>
        <div class="tw-w-1/3 tw-px-2">
          <div>
            <div class="form-group m-b-10">
              <label class="col-form-label control-label required">{{
                $t("payUserVariable.description")
              }}</label>
              <div>
                <Input
                  v-model="model.description"
                  :class="{ 'is-invalid': errors.has('description') }"
                />
                <div class="invalid-feedback" v-if="errors.has('description')">
                  {{ errors.first("description") }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tw-w-1/3 tw-px-2">
          <div>
            <div class="form-group m-b-10">
              <label class="col-form-label control-label required">{{
                $t("payUserVariable.dataType")
              }}</label>
              <div>
                <Select
                  v-model="model.data_type"
                  :class="{ 'is-invalid': errors.has('data_type') }"
                >
                  <Option value="Numeric">Numeric</Option>
                  <Option value="String">String</Option>
                </Select>
                <div class="invalid-feedback" v-if="errors.has('data_type')">
                  {{ errors.first("data_type") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tw-mt-3">
        <div class="alert alert-info fade show tw-flex tw-items-center">
          <i class="fas fa-info-circle tw-text-xl"></i>
          <span class="ml-2">Click on variables to compose a new formula.</span>
        </div>
        <div class="tw-flex">
          <div class="tw-w-1/2 tw-pr-3">
            <system-variables
              @select-variable="onSelectSystemVariable"
              ref="system_variables"
            />
          </div>
          <div class="tw-w-1/2 tw-pl-3">
            <user-variables
              @select-variable="onSelectSystemVariable"
              ref="user_variables"
            />
          </div>
        </div>
      </div>
      <div class="row tw-pt-4">
        <div class="col-md-12">
          <ts-button size="sm" class="tw-mr-2" @click="onSignClick('+')">
            <i class="fas fa-plus"></i>
          </ts-button>
          <ts-button size="sm" class="tw-mr-2" @click="onSignClick('-')">
            <i class="fas fa-minus"></i>
          </ts-button>
          <ts-button size="sm" class="tw-mr-2" @click="onSignClick('*')">
            <i class="fas fa-asterisk"></i>
          </ts-button>
          <ts-button size="sm" class="tw-mr-2" @click="onSignClick('/')">
            <i class="fas fa-divide"></i>
          </ts-button>
          <ts-button size="sm" class="tw-mr-2" @click="onSignClick('(')">
            <span class="tw-font-extrabold">(</span>
          </ts-button>
          <ts-button size="sm" class="tw-mr-2" @click="onSignClick(')')">
            <span class="tw-font-extrabold">)</span>
          </ts-button>
        </div>
      </div>
      <div class="row tw-pt-2">
        <div class="col-md-12">
          <div class="form-group m-b-10">
            <label class="col-form-label control-label required">{{
              $t("payUserVariable.formula")
            }}</label>
            <div>
              <Input
                v-model="model.formula"
                type="textarea"
                :class="{ 'is-invalid': errors.has('formula') }"
                ref="txtFormula"
                :rows="3"
              />
              <div class="invalid-feedback" v-if="errors.has('formula')">
                {{ errors.first("formula") }}
              </div>
              <div class="valid-feedback" v-if="correctSyntaxMessage">
                {{ correctSyntaxMessage }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row tw-pt-6">
        <div class="col-md-12 col-lg-12 tw-flex tw-space-x-2">
          <ts-button
            outline
            color="primary"
            :waiting="checkingSyntax"
            :disabled="model.formula.trim() === ''"
            @click="checkFormulaSyntax"
            >{{ $t("payUserVariable.checkSyntax") }}</ts-button
          >
          <span class="tw-flex-grow"></span>
          <ts-button @click.prevent="() => $emit('cancel')" class="btn-gray">
            {{ $t("cancel") }}</ts-button
          >
          <ts-button
            v-if="!isUpdate"
            color="primary"
            outline
            :waiting="waiting_new"
            :disabled="waiting_new || waiting"
            @click.prevent="onSaveAddNew"
          >
            {{ $t("saveAddNew") }}</ts-button
          >
          <ts-button
            color="primary"
            v-if="!isUpdate"
            :waiting="waiting"
            :disabled="waiting_new || waiting"
            @click.prevent="onSave"
          >
            {{ $t("save") }}</ts-button
          >
          <ts-button
            v-if="isUpdate"
            color="primary"
            @click.prevent="onUpdate"
            :waiting="waiting"
          >
            {{ $t("update") }}</ts-button
          >
        </div>
      </div>
    </form>
    <Spin fix v-if="loading"></Spin>
  </div>
</template>

<script>
import { Errors } from "form-backend-validation";
import { cloneDeep, isEmpty } from "lodash";
import SystemVariables from "./system-variables";
import UserVariables from "./user-variables";
import UserVariableApi from "@/api/payroll/pay-user-variable";
import { mapState } from "vuex";

export default {
  name: "pay-item-overtime-form",
  components: {
    SystemVariables,
    UserVariables,
  },
  data() {
    const model = {
      uv_name: null,
      description: null,
      formula: "",
      data_type: null,
    };

    return {
      newModel: model,
      model: cloneDeep(model),
      errors: new Errors(),
      waiting_new: false,
      waiting: false,
      loading: false,
      checkingSyntax: false,
      correctSyntaxMessage: null,
      gettingVariableName: false,
    };
  },
  computed: {
    ...mapState("payroll/payUserVariable", ["edit_data"]),
    isUpdate() {
      return !isEmpty(this.edit_data);
    },
  },
  methods: {
    async fetchResource() {
      this.loading = true;
      await this.getVariableName();
      await this.$refs.system_variables.getSystemVariables();
      await this.$refs.user_variables.getUserVariables();
      this.loading = false;
    },
    clearInput() {
      this.errors = new Errors();
      this.model.uv_name = null;
      this.model.description = null;
      this.model.formula = "";
      this.model.data_type = null;
      this.fetchResource();
    },
    checkFormulaSyntax() {
      this.checkingSyntax = true;
      this.correctSyntaxMessage = "";

      UserVariableApi.checkUserVariableSyntax(this.model.formula)
        .then((response) => {
          this.errors.clear("formula");
          this.correctSyntaxMessage = response.message;
        })
        .catch((error) => {
          this.errors = new Errors({
            ...this.errors.all(),
            formula: error.errors.formula,
          });
        })
        .finally(() => {
          this.checkingSyntax = false;
        });
    },
    onSignClick(sign) {
      this.model.formula = `${this.model.formula} ${sign} `;
      this.$refs.txtFormula.focus({
        cursor: "end",
      });
    },
    getVariableName() {
      if (!this.isUpdate) {
        this.gettingVariableName = true;

        UserVariableApi.getNewUserVariableName().then((response) => {
          this.model.uv_name = response.data;
          this.gettingVariableName = false;
        });
      }
    },
    onSelectSystemVariable(variable) {
      this.model.formula = `${this.model.formula}${variable}`;
      this.$refs.txtFormula.focus({
        cursor: "end",
      });
    },
    async onSave() {
      this.errors = new Errors();
      this.waiting = true;
      this.$store
        .dispatch("payroll/payUserVariable/store", this.model)
        .then((response) => {
          this.notice({
            type: response.message_id == 0 ? "success" : "warning",
            text: response.message,
          });
          this.$emit("cancel");
          this.$emit("fetchData");
        })
        .catch((error) => {
          this.errors = new Errors(error.errors);
          this.notice({ type: "error", text: error.message });
        })
        .finally(() => {
          this.waiting = false;
        });
    },
    onSaveAddNew() {
      this.errors = new Errors();
      this.waiting_new = true;
      this.$store
        .dispatch("payroll/payUserVariable/store", this.model)
        .then((response) => {
          this.notice({
            type: response.message_id == 0 ? "success" : "warning",
            text: response.message,
          });
          this.$emit("fetchData");
          this.clearInput();
        })
        .catch((error) => {
          this.errors = new Errors(error.errors);
          this.notice({ type: "error", text: error.message });
        })
        .finally(() => {
          this.waiting_new = false;
        });
    },
    onUpdate() {
      this.waiting = true;
      this.$store
        .dispatch("payroll/payUserVariable/update", {
          id: this.edit_data.id,
          data: this.model,
        })
        .then((response) => {
          this.notice({
            type: response.message_id == 0 ? "success" : "warning",
            text: response.message,
          });
          this.$emit("cancel");
          this.$emit("fetchData");
        })
        .catch((error) => {
          this.errors = new Errors(error.errors);
          this.notice({ type: "error", text: error.message });
        })
        .finally(() => {
          this.waiting = false;
        });
    },
    setEditData() {
      if (!isEmpty(this.edit_data)) {
        this.model.uv_name = this.edit_data.uv_name;
        this.model.description = this.edit_data.description;
        this.model.formula = this.edit_data.formula;
        this.model.data_type = this.edit_data.data_type;
      }
      this.fetchResource();
    },
    notice(not) {
      this.$Notice[not.type]({
        title: "PAYROLL FORMULA",
        desc: not.text,
      });
    },
  },
};
</script>
